.form-group {
  margin: 0 0 1rem;

  .invalid-feedback {
    display: block;
    padding-left: 20px;
  }
}

.cs_custom_label {
  margin: 0 0 5px;
}

.cs_custom_txt_box,
.cs_custom_select {
  padding: 10px 20px;
  border-radius: 2.4em;
  outline: none;
  transition: all 0.3s ease;
  border: 1px solid rgba(48, 123, 196, 0.5);
  background-color: transparent;

  &:focus,
  &:active {
    outline: none;
    border-color: rgba(48, 123, 196, 1);
    box-shadow: none;
  }
}
