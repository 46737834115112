.cs_kideny_education {
  .cs_page_heading {
    &_in {
      .cs_quick_faq {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 8px;

        h5 {
          margin: 0;
          padding: 0;
          font-size: 20px;
          font-weight: 400;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        &_btn {
          align-items: center;
          background: #dfdfdf;
          border: none;
          border-radius: 8px;
          box-shadow: none;
          color: #fff;
          display: inline-flex;
          font-weight: 500;
          gap: 8px;
          justify-content: center;
          outline: none;
          padding: 1px 5px;
          position: relative;

          img {
            width: 16px;
            height: 16px;
            margin: 0 0 3px;
            transform: rotate(90deg);
          }

          &::after {
            display: none;
          }

          &:hover,
          &:focus,
          &:active,
          &:visited {
            & + .dropdown-menu {
              display: block;
            }
          }
        }

        .dropdown-menu {
          left: auto;
          right: 0;
          margin: 12px 0 0;
        }
      }
    }
  }

  &.cs_important_remember_bg {
    background-image: url(../../../public/images/home/kidney-education-bg.png);
    // background-repeat: no-repeat;
  }
}

@media (max-width: 575px) {
  .cs_kideny_education {
    .cs_page_heading {
      &_in {
        .cs_quick_faq {
          .dropdown-menu {
            left: 0;
            right: auto;
            margin: 12px 0 0;
          }
        }
      }
    }
  }
}

// .cs_kidney_education_banner {
//   &.cs_banner {
//     &.cs_style_3 {
//       .cs_banner_img {
//         width: 43%;
//       }
//     }
//   }
// }

.cs_healthy_kidneys {
  &.cs_hero {
    &.cs_style_1 {
      padding-bottom: 0;
      height: auto;

      // .cs_hero_wrap {
      //   height: auto;
      // }

      .cs_hero_img {
        bottom: 0;
      }

      .container {
        .cs_hero_title,
        .cs_hero_subtitle {
          max-width: 850px;
        }
        .cs_hero_subtitle {
          margin-bottom: 20px;
        }
      }
    }
  }

  .cs_banner {
    &.cs_style_3 {
      &.cs_kidney_education_banner {
        min-height: 370px;
        padding: 0;

        .cs_banner_img {
          align-items: center;
          justify-content: flex-end;
          left: auto;
          padding: 0;
          right: 0;
          width: 35%;
        }

        .cs_banner_text {
          margin-left: 0;
          margin-right: auto;
          padding-left: 0;
          padding-top: 20px;
          padding-bottom: 15px;
          width: 65%;

          .cs_banner_title,
          .cs_hero_title,
          .cs_banner_subtitle,
          .cs_hero_subtitle {
            color: #fff;
          }

          .cs_banner_subtitle {
            max-width: none;
            margin-bottom: 15px !important;
          }
        }
      }
    }
  }

  .cs_shape_wrap {
    .cs_random_features {
      .cs_iconbox {
        &.cs_style_13 {
          .cs_iconbox_icon {
            padding: 0;
            .cs_iconbox_icon_in {
              background: none;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .cs_iconbox_carousel_1 {
    .slick-slider {
      &:before {
        background: linear-gradient(
          154deg,
          rgb(247, 146, 38) 0%,
          rgb(240, 84, 69) 100%
        );
      }

      .slick-list {
        .slick-track {
          .slick-slide {
            .cs_iconbox {
              &.cs_style_10 {
                .cs_iconbox_icon {
                  padding: 0;
                  border: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .cs_iconbox {
    &.cs_style_1 {
      .cs_iconbox_top {
        .cs_iconbox_icon {
          padding: 0;
          background: none;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .cs_healthy_kidneys {
    &.cs_hero {
      &.cs_style_1 {
        .cs_hero_img {
          margin: 0;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .cs_healthy_kidneys {
    &.cs_hero {
      &.cs_style_1 {
        .container {
          .cs_hero_title,
          .cs_hero_subtitle {
            max-width: 580px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .cs_healthy_kidneys {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          .cs_banner_text {
            padding: 40px 15px 5px;
            width: 70%;

            .cs_banner_title,
            .cs_hero_title {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .cs_healthy_kidneys {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          .cs_banner_text {
            padding: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

.cs_about_kidney {
  .cs_iconbox_8_wrap {
    background: none;

    .cs_iconbox {
      &.cs_style_8 {
        .cs_iconbox_icon {
          border: none;
        }

        .cs_iconbox_title,
        .cs_iconbox_subtitle {
          color: #000;
        }
      }
    }
  }

  &.cs_banner {
    &.cs_style_5 {
      padding-bottom: 0;

      .container {
        padding-bottom: 1rem;

        .cs_banner_text {
          .cs_banner_subtitle {
            margin-bottom: 30px !important;
          }
        }
      }
    }
  }

  .cs_testimonial {
    &.cs_style_2 {
      .cs_testimonial_text {
        padding: 60px 30px 30px 124px;
        margin-bottom: 0;
        min-height: 264px;
      }
    }
  }

  .cs_list {
    &.cs_style_1 {
      &.cs_list_custom-style {
        .cs_list_title {
          margin-bottom: 5px;
        }

        .cs_list_sub-title {
          padding-left: 50px;
          margin-bottom: 25px;
        }
      }
    }
  }

  .container {
    .cs_banner {
      &.cs_style_9 {
        padding: 15px;
        margin-top: 0;

        .cs_list {
          &.cs_style_1 {
            .cs_list_title {
              img {
                width: 29px;
                height: 24px;
              }
            }

            ul {
              li {
                &:before {
                  background-color: $kidneyEducation;
                }
              }
            }
          }
        }
      }
    }
  }

  .cs_doctor_details {
    .testimonial-title,
    .testimonial-sub-title {
      color: $white;
      // color: red;
    }

    .cs_list {
      &.cs_style_1 {
        .cs_list_title {
          img {
            width: 29px;
            height: 24px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .cs_about_kidney {
    .cs_testimonial {
      &.cs_style_2 {
        .cs_testimonial_text {
          min-height: 325px;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .cs_about_kidney {
    &.cs_banner {
      &.cs_style_5 {
        .cs_banner_img {
          width: 50%;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .cs_about_kidney {
    .cs_testimonial {
      &.cs_style_2 {
        .cs_testimonial_text {
          min-height: 450px;
        }
      }
    }
  }
}

@media (max-width: 575px) {

  
  .cs_about_kidney {
    .cs_testimonial {
      &.cs_style_2 {
        .cs_testimonial_text {
          padding: 80px 25px 30px 45px;
          margin-bottom: 35px;
          min-height: auto;
        }
      }
    }
  }
}

.cs_dialysis {
  .cs_banner {
    &.cs_style_3 {
      &.cs_kidney_education_banner {
        // padding: 225px 0 50px;
        min-height: 458px;
        padding: 0;

        .cs_banner_img {
          padding: 0;
          right: -16%;
          left: auto;
          align-items: center;
          justify-content: flex-end;
        }

        .cs_banner_subtitle {
          max-width: none;
        }

        .cs_banner_text {
          padding-top: 100px;
          padding-left: 0;
          margin-left: 0;
          margin-right: auto;
          width: 58%;

          .cs_banner_title,
          .cs_hero_title,
          .cs_banner_subtitle {
            color: #fff;
          }
        }
      }
    }
  }

  &_need {
    .cs_section_heading {
      .cs_section_title {
        color: $kidneyEducation;
        
      }
    }
  }

  .cs_iconbox {
    &.cs_style_1 {
      .cs_iconbox_top {
        .cs_iconbox_icon {
          background: transparent;
          padding: 0;
        }

        .cs_iconbox_title {
          text-decoration: underline;
          text-decoration-color: $kidneyEducation;
        }
      }

      .cs_iconbox_text {
        a {
          font-weight: 500;
          color: $kidneyEducation;
          background-position: left bottom;
          background-repeat: no-repeat;
          background-size: 100% 2px, 0 2px;
          text-decoration: none;
          transition: 0.5s;
          background-image: linear-gradient(to right, transparent, transparent),
            linear-gradient(to right, #f16426, #f99747);

          &:hover {
            background-size: 100% 0, 100% 2px;
          }
        }
      }
    }
  }

  .cs_iconbox {
    &.cs_style_6 {
      .cs_iconbox_icon {
        background: transparent;
        padding: 0;
      }

      .cs_iconbox_title {
        text-decoration: underline;
        text-decoration-color: $kidneyEducation;
      }
    }
  }

  .cs_departments {
    &.cs_style_1 {
      .cs_section_heading {
        .cs_section_title,
        p {
          color: #fff;
        }
      }

      .cs_department_list {
        .cs_banner {
          &.cs_style_9 {
            margin: 0;
            padding: 50px;

            .cs_accordian_body {
              ul {
                li {
                  &:before {
                    background-color: $kidneyEducation;
                  }

                  .cs_heading_color {
                    text-decoration: underline;
                    text-decoration-color: $kidneyEducation;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .cs_iconbox_8_wrap {
    background: none;

    .cs_iconbox {
      &.cs_style_8 {
        .cs_iconbox_icon {
          border: none;
        }

        // .cs_iconbox_title {
        //   text-decoration: underline;
        //   text-decoration-color: $kidneyEducation;
        // }

        .cs_iconbox_title,
        .cs_iconbox_subtitle {
          color: #000;
        }
      }
    }
  }

  &.cs_blog_details {
    .cs_shape_wrap {
      .cs_shape_1.cs_position_4 {
        display: none;
      }

      .cs_about_img {
        padding-right: 20px;

        img {
          border-radius: 20px;
        }
      }
    }

    .cs_about_content {
      .cs_section_heading {
        p {
          font-size: 42px;
          font-weight: 500;
          line-height: 50px;
        }
      }
    }

    blockquote {
      margin: 0;
      padding: 0;
      min-height: auto;

      p {
        padding: 40px 50px;
        border-radius: 0 20px 0 20px;

        &::before {
          display: none;
        }
      }
    }
  }
}

@media (min-width: 1920px) {
  .cs_dialysis {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          .cs_banner_img {
            right: -8%;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .cs_dialysis {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          min-height: 375px;

          .cs_banner_img {
            right: -14%;

            img {
              height: 100%;
            }
          }

          .cs_banner_text {
            padding-top: 45px;
            padding-right: 15px;
            width: 65%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .cs_dialysis {
    .cs_department_list {
      .cs_list_sec2 {
        padding-top: 30px;
      }
    }
  }
}

@media (max-width: 768px) {
  .cs_dialysis {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          .cs_banner_text {
            padding-top: 0;
            padding-right: 0;
            width: 100%;
          }
        }
      }
    }

    &.cs_blog_details {
      .cs_shape_wrap {
        .cs_about_img {
          padding-right: 0;
        }
      }

      .cs_about_content {
        .cs_section_heading {
          p {
            font-size: 42px;
            font-weight: 500;
            line-height: 50px;
          }
        }
      }

      blockquote {
        margin: 0;
        padding: 0;
        min-height: auto;

        p {
          border-radius: 0 0 20px 20px;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .cs_dialysis {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          min-height: 450px;
        }
      }
    }

    .cs_departments {
      &.cs_style_1 {
        .cs_department_list {
          .cs_banner {
            &.cs_style_9 {
              margin: 0;
              padding: 50px 15px;
            }
          }

          .cs_list_sec2 {
            padding-top: 30px;
          }

          .cs_list {
            &.cs_style_1 {
              ul {
                padding-left: 12px;
              }
            }
          }
        }
      }
    }

    &.cs_blog_details {
      .cs_shape_wrap {
        .cs_about_img {
          padding-right: 0;
        }
      }

      blockquote {
        p {
          border-radius: 0 0 20px 20px;
        }
      }
    }
  }
}

.cs_transplant {
  .cs_banner {
    &.cs_style_5 {
      padding: 225px 0 50px;

      .cs_banner_subtitle {
        max-width: none;
      }
    }

    &.cs_style_3 {
      &.cs_kidney_education_banner {
        min-height: 458px;
        padding: 0;

        .cs_banner_img {
          padding: 0;
          right: 0;
          left: auto;
          align-items: center;
          justify-content: flex-end;
        }

        .cs_banner_subtitle {
          max-width: none;
        }

        .cs_banner_text {
          padding-top: 70px;
          padding-left: 0;
          margin-left: 0;
          margin-right: auto;
          width: 50%;

          .cs_banner_title,
          .cs_hero_title,
          .cs_banner_subtitle {
            color: #fff;
          }
        }
      }
    }

    &.cs_style_1 {
      .cs_banner_subtitle {
        color: $white;
      }
    }
  }

  .cs_shape_wrap {
    .cs_random_features {
      .cs_iconbox.cs_style_13 {
        .cs_iconbox_icon {
          padding: 0;

          .cs_iconbox_icon_in {
            padding: 0;
            background: none;
          }
        }
      }
    }
  }

  .cs_banner {
    &.cs_style_2 {
      .cs_section_heading {
        .cs_section_title,
        p {
          color: #274760;
        }
      }
    }
  }

  .cs_iconbox {
    &.cs_style_1 {
      .cs_iconbox_top {
        .cs_iconbox_icon {
          padding: 0;
          background: none;
        }
      }
    }
  }

  .cs_gray_bg_1 {
    &.cs_half_bg {
      background: none;

      .cs_about {
        &.cs_style_3 {
          background-image: url(../../../public/images/home/kidney-education-bg.png);
          // background-repeat: no-repeat;

          .cs_section_heading {
            &.cs_style_1 {
              padding: 90px 80px;

              .cs_section_title,
              .cs_section_minititle {
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  .cs_cta {
    &.cs_style_1 {
      padding: 125px 60px 20px 50%;

      h3 {
        color: $kidneyEducation;
      }
    }
  }

  .cs_shape_wrap {
    .flex-column-reverse {
      .cs_iconbox {
        &.cs_style_6 {
          .cs_iconbox_icon {
            padding: 0;
            background: none;
          }
        }
      }
    }
  }

  .cs_blog_details {
    blockquote {
      margin: 0;
      padding: 0;
      min-height: auto;

      p {
        padding: 40px 50px;
        border-radius: 0 20px 0 20px;
        background-image: url(../../../public/images/home/kidney-education-bg.png);
        // background-repeat: no-repeat;

        &::before {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .cs_transplant {
    .cs_banner {
      &.cs_style_5 {
        .cs_banner_img {
          width: 50%;
          bottom: 12%;
        }
      }

      &.cs_style_3 {
        &.cs_kidney_education_banner {
          min-height: 365px;

          .cs_banner_text {
            padding-top: 15px;
            padding-right: 15px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .cs_transplant {
    .cs_list_sec2 {
      padding-top: 30px;
    }

    .cs_cta {
      &.cs_style_1 {
        padding: 30px 20px 5px 45%;
      }
    }
  }
}

@media (max-width: 768px) {
  .cs_transplant {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          min-height: 300px;

          .cs_banner_text {
            padding: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .cs_transplant {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          min-height: 70px;

          .cs_banner_text {
            padding: 50px 0;
            width: 100%;
          }
        }
      }
    }

    .cs_about {
      &.cs_style_3 {
        .cs_section_heading {
          &.cs_style_1 {
            padding: 30px 20px 40px;
          }
        }
      }
    }

    .cs_list_sec2 {
      padding-top: 30px;
    }

    .cs_cta {
      &.cs_style_1 {
        padding: 100px 24px 90px;
      }
    }
  }
}

.cs_kidney_biopsy {
  .cs_banner {
    &.cs_style_3 {
      &.cs_kidney_education_banner {
        // padding: 225px 0 50px;
        min-height: 458px;
        padding: 0;

        .cs_banner_img {
          padding: 0;
          right: 0;
          left: auto;
          align-items: center;
          justify-content: flex-end;
        }

        .cs_banner_subtitle {
          max-width: none;
        }

        .cs_banner_text {
          padding-top: 50px;
          padding-left: 0;
          margin-left: 0;
          margin-right: auto;
          width: 59%;

          .cs_banner_title,
          .cs_hero_title,
          .cs_banner_subtitle {
            color: #fff;
          }
        }
      }
    }
  }

  .cs_shape_wrap {
    .flex-column-reverse {
      .cs_iconbox {
        &.cs_style_6 {
          .cs_iconbox_icon {
            padding: 0;
            background: none;
          }
        }
      }
    }
  }

  .cs_gray_bg_1 {
    &.cs_half_bg {
      background: none;

      .cs_about {
        &.cs_style_3 {
          background-image: url(../../../public/images/home/kidney-education-bg.png);
          // background-repeat: no-repeat;

          .cs_section_heading {
            &.cs_style_1 {
              .cs_section_title,
              .cs_section_minititle {
                color: $white;
              }

              .cs_section_title {
                margin-bottom: 15px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .cs_kidney_biopsy {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          .cs_banner_text {
            padding-top: 30px;
            padding-right: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .cs_kidney_biopsy {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          min-height: 365px;

          .cs_banner_text {
            padding-top: 0;
            padding-right: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .cs_kidney_biopsy {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          min-height: 460px;
        }
      }
    }
  }
}

.cs_av_fistula {
  &.cs_banner {
    &.cs_style_5 {
      padding-bottom: 0;

      .container {
        padding-bottom: 1rem;

        .cs_banner_text {
          .cs_banner_subtitle {
            margin-bottom: 30px !important;
          }
        }
      }
    }
  }

  .cs_banner {
    &.cs_style_3 {
      &.cs_kidney_education_banner {
        // padding: 225px 0 50px;
        min-height: 490px;
        padding: 0;

        .cs_banner_img {
          padding: 0;
          right: 0;
          left: auto;
          align-items: center;
          justify-content: flex-end;
        }

        .cs_banner_subtitle {
          max-width: none;
        }

        .cs_banner_text {
          padding-top: 25px;
          padding-left: 0;
          margin-left: 0;
          margin-right: auto;
          width: 55%;

          .cs_banner_title,
          .cs_hero_title,
          .cs_banner_subtitle {
            color: #fff;
          }
        }
      }
    }
  }

  .cs_iconbox {
    &.cs_style_1 {
      .cs_iconbox_top {
        .cs_iconbox_icon {
          padding: 0;
          background: none;
        }
      }
    }
  }

  .cs_shape_wrap {
    .cs_shape_1 {
      &.cs_position_2 {
        top: 0;
        display: none;
      }
    }

    .cs_iconbox_3_wrap {
      .cs_iconbox {
        &.cs_style_3 {
          &::before {
            background-color: $kidneyEducation;
          }

          .cs_iconbox_left {
            .cs_iconbox_icon {
              padding: 0;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .cs_av_fistula {
    &.cs_banner {
      &.cs_style_5 {
        .cs_banner_img {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .cs_av_fistula {
    &.cs_banner {
      &.cs_style_5 {
        .cs_banner_img {
          width: 50%;
          bottom: 12%;
        }
      }
    }

    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          .cs_banner_img {
            right: 0;
          }

          .cs_banner_text {
            padding-top: 30px;
            padding-right: 15px;
            width: 65%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .cs_av_fistula {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          .cs_banner_text {
            padding: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .cs_av_fistula {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          .cs_banner_text {
            padding: 50px 0;
            width: 100%;
          }
        }
      }
    }
  }
}

.cs_catheters {
  .cs_random_features {
    &_col {
      .cs_iconbox {
        &.cs_style_13 {
          .cs_iconbox_icon {
            padding: 0;

            .cs_iconbox_icon_in {
              padding: 0;
              background: none;
            }
          }
        }
      }
    }
  }

  .cs_iconbox_12_wrap {
    .cs_iconbox {
      &.cs_style_12 {
        .cs_iconbox_info {
          .cs_iconbox_circle {
            background-color: $kidneyEducation;
          }
        }

        .cs_iconbox_icon,
        a {
          padding: 0;
          cursor: default;

          &:hover {
            color: inherit;
          }

          img {
            filter: none;
          }
        }
      }
    }
  }
}

.cs_medicines {
  &.cs_banner {
    &.cs_style_3 {
      .cs_banner_text {
        width: 100%;

        p {
          max-width: none;
          margin-bottom: 30px;
          text-align: left;
        }
      }
    }
  }

  .cs_banner {
    &.cs_style_5 {
      padding: 225px 0 50px;

      .cs_banner_subtitle {
        max-width: none;
      }
    }

    &.cs_style_3 {
      &.cs_kidney_education_banner {
        min-height: 458px;
        padding: 0;

        .cs_banner_img {
          padding: 0;
          right: 0;
          left: auto;
          align-items: center;
          justify-content: flex-end;
        }

        .cs_banner_subtitle {
          max-width: none;
        }

        .cs_banner_text {
          padding-top: 50px;
          padding-left: 0;
          margin-left: 0;
          margin-right: auto;
          width: 50%;

          .cs_banner_title,
          .cs_hero_title,
          .cs_banner_subtitle {
            color: #fff;
          }
        }
      }
    }
  }

  .cs_banner {
    &.cs_style_1 {
      .cs_banner_title,
      .cs_banner_subtitle {
        color: $white;
      }
    }
  }

  .cs_section_heading {
    &.cs_style_1 {
      h3 {
        color: #274760;
        text-transform: capitalize !important;
        font-size: 24px;
        font-weight: 700;
      }
    }
  }

  .cs_shape_wrap {
    .cs_shape_1 {
      display: none;
    }
  }
}

@media (max-width: 1024px) {
  .cs_medicines {
    &.cs_banner {
      &.cs_style_3 {
        .container,
        .container-lg,
        .container-md,
        .container-sm {
          max-width: 960px;
        }
      }
    }

    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          .cs_banner_text {
            padding-top: 15px;
            padding-right: 15px;
            width: 55%;
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .cs_medicines {
    &.cs_banner {
      &.cs_style_3 {
        .container {
          max-width: 720px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .cs_medicines {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          min-height: 300px;

          .cs_banner_text {
            padding: 0;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .cs_medicines {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_education_banner {
          min-height: 70px;

          .cs_banner_text {
            padding: 50px 0;
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 465px) {
  .cs_gray_bg_1 {
    &.cs_half_bg {
      .cs_about {
        &.cs_style_3 {

          .cs_section_heading {
            &.cs_style_1 {
              padding: 50px 30px !important;
            }
          }
        }
      }
    }
  }
}

