* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.flex1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navbar-one {
    padding: 0 65px;
    width: 100%;
    background-color: gray;
    color: white;
    height: 40px;
    position: fixed !important;
    width: 100%;
    z-index: 999;

}

.left>div+div {
    margin-left: 30px;
}

.right>div+div {
    margin-left: 30px;
}

.email a {
    margin-left: 6px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1px;
}

.call a {
    text-decoration: none;
    margin-left: 6px;
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1px;
}

.logins span,
.oneclick a {
    cursor: pointer;
    font-size: 14px;
    letter-spacing: 1px;
}

.email a:hover,
.call a:hover,
.logins:hover,
.oneclick a:hover {
    color: black;
    transition: all 0.5s;
}

@media (max-width: 750px) {

    // .right {
    //     display: none;
    // }
    // .left > div + div{
    //     margin-left: 5px;
    // }
   
    .flex {
        display: block;
        justify-content: space-between;
        align-items: center;
    }
    .navbar-one {
        padding: 0 25px;
        width: 100%;
        background-color: gray;
        color: white;
        height: 50px;
        position: fixed !important;
        width: 100%;
        z-index: 999;
    }
    // .oneclick {
    //     // display: none;
    // }
    .call{
        display: none;
    }
}