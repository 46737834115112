.cs_accordians {
  &.cs_style1 {
    &.cs_accordians_kidney_education {
      .cs_accordian {
        border-color: $kidneyEducation;

        .cs_accordian_head {
          &:hover,
          &:focus {
            background: linear-gradient(
              119deg,
              rgb(247, 146, 38) 17.16%,
              rgb(240, 84, 69) 89.78%
            );
            border-radius: 19px;
            color: $white;

            .cs_accordian_arrow {
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }

          .cs_accordian_arrow {
            svg {
              path {
                fill: $kidneyEducation;
              }
            }
          }
        }

        &.active {
          .cs_accordian_head {
            color: #fff;
            border-bottom: 1px solid $kidneyEducation;
            background: linear-gradient(
              119deg,
              rgb(247, 146, 38) 17.16%,
              rgb(240, 84, 69) 89.78%
            );
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .cs_accordian_arrow {
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }
        }

        .cs_accordian_body_wrap {
          .cs_accordian_body {
            .cs_list {
              ul {
                li {
                  &:before {
                    background-color: $kidneyEducation;
                  }

                  .cs_heading_color {
                    text-decoration: underline;
                    text-decoration-color: $kidneyEducation;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.cs_accordians_our_services {
      .cs_accordian {
        border-color: $ourServices;
        width: 100%;

        .cs_accordian_head {
          &:hover,
          &:focus {
            background: linear-gradient(119deg, #40bfbb 17.16%, #079247 89.78%);
            border-radius: 19px;
            color: $white;

            .cs_accordian_arrow {
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }

          .cs_accordian_arrow {
            svg {
              path {
                fill: $ourServices;
              }
            }
          }
        }

        &.active {
          .cs_accordian_head {
            color: #fff;
            border-bottom: 1px solid $ourServices;
            background: linear-gradient(
              119deg,
              rgb(64, 191, 187) 17.16%,
              rgb(7, 146, 71) 89.78%
            );
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .cs_accordian_arrow {
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }
        }

        .cs_accordian_body_wrap {
          .cs_accordian_body {
            .cs_list {
              ul {
                li {
                  &:before {
                    background-color: $ourServices;
                  }

                  .cs_heading_color {
                    text-decoration: underline;
                    text-decoration-color: $ourServices;
                  }
                }
              }
            }
          }
        }
      }
    }

    .cs_accordian_body_wrap {
      .cs_accordian_body {
        .cs_list {
          ul {
            padding-left: 0;
          }
        }
      }
    }

    &.cs_accordians_with_icon {
      .cs_accordian {
        .cs_accordion_head_icon {
          display: flex;
          align-items: center;
          gap: 8px;

          img {
            width: 26px;
            height: auto;
          }
        }
      }
    }

    .cs_accordian {
      .cs_accordian_body_wrap {
        .cs_accordian_body {
          .cs_list_custom-style {
            p,
            ul {
              padding-left: 0;
            }
          }
        }
      }
    }

    &.cs_accordians_only_title {
      .cs_accordian {
        .cs_accordian_body_wrap {
          .cs_accordian_body {
            .cs_list {
              ul {
                li {
                  .cs_heading_color {
                    text-decoration: none;
                    text-decoration-color: transparent;
                  }
                }
              }
            }
          }
        }
      }
    }

    &.cs_faq_accordians_kidney_education {
      .cs_accordian {
        border-color: $kidneyEducation;

        .cs_accordian_head {
          &:hover,
          &:focus {
            background: linear-gradient(
              119deg,
              rgb(247, 146, 38) 17.16%,
              rgb(240, 84, 69) 89.78%
            );
            border-radius: 14px;
            color: $white;

            .cs_accordian_arrow {
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }

          .cs_accordian_arrow {
            svg {
              path {
                fill: $kidneyEducation;
              }
            }
          }
        }

        &.active {
          background: linear-gradient(
            119deg,
            rgb(247, 146, 38) 17.16%,
            rgb(240, 84, 69) 89.78%
          );

          .cs_accordian_head {
            color: $white;

            .cs_accordian_arrow {
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }

          .cs_accordian_body_wrap {
            .cs_accordian_body {
              background: $white;
              padding: 24px 24px;
              border-radius: 0 0 14px 14px;
            }
          }
        }
      }
    }

    &.cs_faq_accordians_our_services {
      .cs_accordian {
        border-color: $ourServices;

        .cs_accordian_head {
          &:hover,
          &:focus {
            background: linear-gradient(119deg, #40bfbb 17.16%, #079247 89.78%);
            border-radius: 14px;
            color: $white;

            .cs_accordian_arrow {
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }

          .cs_accordian_arrow {
            svg {
              path {
                fill: $ourServices;
              }
            }
          }
        }

        &.active {
          background: linear-gradient(
            119deg,
            rgb(64, 191, 187) 17.16%,
            rgb(7, 146, 71) 89.78%
          );

          .cs_accordian_head {
            color: $white;

            .cs_accordian_arrow {
              svg {
                path {
                  fill: $white;
                }
              }
            }
          }

          .cs_accordian_body_wrap {
            .cs_accordian_body {
              background: $white;
              padding: 24px 24px;
              border-radius: 0 0 14px 14px;
            }
          }
        }
      }
    }
  }
}

.cs_accordion_spacing {
  .cs_list {
    &.cs_style_1 {
      &.cs_list_custom-style {
        .cs_list_title {
          margin-bottom: 5px;
        }

        .cs_list_sub-title {
          padding-left: 50px;
          margin-bottom: 25px;
        }
      }
    }
  }
}

.cs_accordion_no_spacing {
  .cs_list {
    &.cs_style_1 {
      &.cs_list_custom-style {
        .cs_list_sub-title {
          margin: 0;
        }
      }
    }
  }
}

.cs_accordion_ref {
  .cs_accordians {
    .cs_accordian {
      box-shadow: 0px 4px 21px 1px rgba(0, 0, 0, 0.1);
    }
  }
}

@media screen and (max-width: 991px) {
  .cs_multi_accord_right {
    padding-top: 20px;
  }
}

@media screen and (max-width: 575px) {
  .cs_multi_accord_right {
    padding-top: 20px;
  }
}
