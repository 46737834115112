.cs_diet_page {
  &_sec {
    background-image: url(../../../public/images/diet/diet-img.jpg);
    padding: 0 0 100px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    min-height: 900px;
  }

  &_container {
    margin: 0 0 0 50px;
  }

  &_image_corner_right {
    position: relative;
    min-height: 697px;

    .cs_diet_image1 {
      margin: 0;
      padding: 0;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}

.cs_diet_btn {
  &_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0 0;

    .cs_diet_btn_back {
      text-align: left;

      button {
        img {
          transform: rotate(180deg);
        }

        &::before {
          background-image: linear-gradient(
            134deg,
            rgb(247, 146, 38) 0%,
            rgb(240, 84, 69) 100%
          );
        }

        &::after {
          background-image: linear-gradient(
            270deg,
            rgb(247, 146, 38) 0%,
            rgb(240, 84, 69) 100%
          );
          opacity: 0;
        }
      }
    }

    .cs_diet_btn_next {
      text-align: right;

      button {
        &::before {
          background-image: linear-gradient(
            134deg,
            rgb(247, 146, 38) 0%,
            rgb(240, 84, 69) 100%
          );
        }

        &::after {
          background-image: linear-gradient(
            270deg,
            rgb(247, 146, 38) 0%,
            rgb(240, 84, 69) 100%
          );
          opacity: 0;
        }
      }
    }
  }
}

.cs_diet_form {
  .form-group {
    .cs_custom_label {
      color: $white;
    }

    .cs_custom_txt_box,
    .cs_custom_select {
      border: 1px solid rgba(255, 255, 255, 0.75);
      background-color: rgba(255, 255, 255, 0.86);
    }
  }

  &_height_sec {
    display: inline-block;

    &::after {
      content: "";
      clear: both;
      display: block;
    }

    .cs_user_height {
      width: 55%;
      float: left;
    }

    .cs_user_height_type {
      width: 42%;
      float: right;
    }
  }
}

.cs_diet_detailed_page {
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 20px;

  .cs_diet_patient_info_sec {
    padding: 25px 20px;
    text-align: center;

    .cs_diet_patient_details {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      h4 {
        font-size: 22px;
        font-weight: 600;
        line-height: 24px;
        margin: 0;
      }

      p {
        font-size: 16px;
        margin: 0;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .cs_diet_page {
    &_sec {
      background-image: url(../../../public/images/diet/diet-img-small.jpg);
      min-height: auto;
    }

    &_container {
      margin: 0;
    }
  }
}

@media screen and (max-width: 575px) {
  .cs_diet_page {
    &_container {
      width: 100%;
      margin: 0;
    }
  }

  .cs_diet_form {
    &_height_sec {
      display: inline-block;

      &::after {
        content: "";
        clear: both;
        display: block;
      }

      .cs_user_height,
      .cs_user_height_type {
        width: 100%;
        float: none;
      }
    }
  }
}
