// App.scss
.faqfilter-section {
  // margin-left: 100px;
  margin-right: 200px;
  font-family: Arial, sans-serif;
  text-align: center;
  margin: 20px;
}

.filter-header {
  margin-left: 40px;
  display: flex;
  align-items: center; // Aligns items vertically center
  justify-content: safe; // Adjusts spacing between title and buttons
}

.filter-title {
  font-size: 1.2em;
  margin-right: 10px; // Space between title and buttons
  color: #333; // Change color as desired
}

.filter-buttons {
  display: flex; // Use flex to align buttons in a row
  gap: 10px; // Space between buttons
}

.filter-buttons {
  margin-bottom: 20px;

  // button {
  //   margin: 0 10px;
  //   padding: 7px 15px;
  //   font-size: 16px;
  //   cursor: pointer;
  //   border: none;
  //   background-color: #4CAF50;
  //   color: white;
  //   border-radius: 90px;

  //   &.active {
  //     background-color: #45a049;
  //     filter: brightness(85%);
  //   }

  //   &:hover {
  //     background-color: #45a049;
  //   }
  // }
}

.button {
  color: white;
  padding: 7px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 90px;
}

.kidney-education {
  background-color: rgb(233, 171, 56);
}

.our-services {
  background-color: rgb(20, 131, 20);
}

.diet {
  background-color: rgb(83, 83, 223);
}

.dialysis-center {
  background-color: rgb(241, 34, 103);
}

.health-record {
  background-color: gray;
}

.active {
  opacity: 0.8;
}
@media (max-width: 700px) {
  .filter-header {
    display: block; // Remove flex for smaller screens
    text-align: center; // Center align content

    .filter-title {
      margin-bottom: 10px;
    }

    .filter-buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; // Center buttons
      gap: 10px; // Space between buttons
    }
  }
}


.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}


// Card.scss
.card {
  flex: 1 1 200px; // Set flex-basis to your new width, e.g., 200px or lower
  max-width: 200px; // Set a maximum width for the card

  // Other existing styles
  width: 180px; // New width for the card
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
  background-color: #fff;

  .card-image {
    width: 100%;
    height: 280px;
    object-fit: cover;
  }

  .card-content {
    padding: 15px;

    .card-title {
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 10px;
      padding-top: 10px;
    }

    .card-text {
      text-align: center;
      font-size: 14px;
      color: #555;
      padding: 10px 20px;
    }
  }
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 40px; // Smaller gap for a more compact layout
  justify-content: center;

  .card {
    flex: 1 1 30%; // Each card takes up 30% of the row
    max-width: 25%;
  }
}


.card-social {
  display: flex;
  justify-content: center;
  margin-top: auto; // Aligns this section to the bottom of the card
  padding: 10px 0;
  border-top: 1px solid #eee; // Optional: line separator
}

.card-social-in {
  display: flex;
  justify-content: space-around;
  width: 150px; // Set space around icons as needed

  a {
    color: #555;
    font-size: 24px; // Adjust icon size
    transition: color 0.3s ease, transform 0.2s ease;

    &:hover {
      color: #007bff; // Change color on hover
      transform: scale(1.1); // Scale on hover
    }
  }
}


@media (max-width: 768px) {
  .card-container .card {
    flex: 1 1 100%; // Each card takes full width on smaller screens
    max-width: 100%; // Ensure no max-width restriction
  }
}