.cs_scroll_indicator {
  &_container {
    background-color: #fff;
    height: 6px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    border-radius: 0;
  }
  &_progressbar {
    height: 6px;
    // background-color: green;
    background-image: linear-gradient(rgb(64, 191, 187), rgb(7, 146, 71));
    border-radius: 0;
  }
}

.cs_scroll_to_lists {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;

  button {
    color: $white;
    margin: 0;
    padding: 12px 20px;
    text-align: center;
    transition: 0.5s;
    background-size: 200% auto;
    box-shadow: 0 0 20px #eee;
    border-radius: 20px;
    display: block;
    font-weight: 500;

    &:hover {
      background-position: right center;
      color: #fff;
      text-decoration: none;
    }
  }

  &.kidney-education {
    button {
      background-image: linear-gradient(
        to right,
        rgb(247, 146, 38),
        rgb(240, 84, 69)
      );
    }
  }

  &.our-services {
    button {
      background-image: linear-gradient(
        to right,
        rgb(64, 191, 187),
        rgb(7, 146, 71)
      );
    }
  }
}

@media (max-width: 575px) {
  .cs_scroll_to_lists {
    // flex-direction: column;
    align-items: flex-start;
  }
}

.cs_scroll_to_sec_container {
  display: flex;
  gap: 1.5vw;
  flex-wrap: wrap;

  .cs_scroll_to_sec {
    display: inline-block;
    margin: 0;

    .cs_scroll_to_lists_sec {
      box-shadow: 0 4px 21px 1px #307bc41a;
      display: flex;
      gap: 1vw;
      transition: all 0.5s ease;
      border-radius: 10px;
      padding-right: 20px;
      cursor: pointer;

      &.kidney-education {
        background-image: linear-gradient(
          to right,
          rgb(247, 146, 38),
          rgb(240, 84, 69)
        );

        &:hover {
          background-image: linear-gradient(
            to right,
            rgb(240, 84, 69),
            rgb(247, 146, 38)
          );
        }
      }

      &.our-services {
        background-image: linear-gradient(
          to right,
          rgb(64, 191, 187),
          rgb(7, 146, 71)
        );

        &:hover {
          background-image: linear-gradient(
            to right,
            rgb(7, 146, 71),
            rgb(64, 191, 187)
          );
        }
      }

      &:hover {
        background-position: right center;
        color: #fff;
        text-decoration: none;
        transform: scale(1.03);
      }

      img {
        flex: none;
        height: 50px;
        width: 50px;
        border-radius: 0;
      }

      .cs_scroll_to_lists_info {
        align-items: center;
        display: flex;
        padding: 0;

        h6 {
          margin: 0;
          padding: 0;
          color: $white;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .cs_scroll_to_sec_container {
    gap: 2vw;
  }
}

@media (max-width: 575px) {
  .cs_scroll_to_sec {
    margin: 0 0 20px;

    &:last-child {
      margin: 0;
    }

    .cs_scroll_to_lists_sec {
      gap: 3vw;
    }

    .cs_scroll_to_grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    }
  }
}

@media (max-width: 400px) {
  .cs_scroll_to_sec {
    .cs_scroll_to_grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    }
  }
}

@media (max-width: 380px) {
  .cs_scroll_to_sec {
    .cs_scroll_to_grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    }
  }
}
