// Home Page Start
.cs_hero {
  &.cs_style_2 {
    &.cs_home_page_banner {
      .cs_hero_text {
        .cs_hero_title {
          color: #ffffff;
          font-size: 62px;
          line-height: 72px;
          margin-bottom: 40px;
        }
        .cs_hero_title2 {
          color: #274760;
          font-size: 62px;
          line-height: 72px;
          margin-bottom: 40px;
          margin-top: -40px;
        }
      }

      .cs_hero_bottom {
        justify-content: space-between;

        .cs_hero_bottom_right {
          .cs_hero_funfact {
            padding: 20px 0 20px 40px;

            .cs_hero_funfact_col {
              h3 {
                color: $primary;
              }

              .cs_nav_icon {
                padding: 0;

                img {
                  filter: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .cs_hero {
    &.cs_style_2 {
      &.cs_home_page_banner {
        .cs_hero_text {
          margin-bottom: 0;

          .cs_hero_title {
            color: #ffffff;
            font-size: 56px;
            line-height: 62px;
            margin-bottom: 30px;
          }
        }

        .cs_hero_bottom {
          justify-content: space-between;

          .cs_hero_bottom_right {
            .cs_hero_funfact {
              padding: 20px 0 20px 10px;

              .cs_hero_funfact_col {
                padding: 0;

                h3 {
                  font-size: 28px;
                  margin: 0;
                }

                .cs_nav_icon {
                  width: 50px;
                  height: 50px;

                  img {
                    filter: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Home Page End

.cs_upload_prescription {
  img {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &_content {
    background: linear-gradient(163deg, #86bbf1, #d2eaef);
    padding: 30px;
    position: relative;
    z-index: 2;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;

    .cs_hero_card_media {
      display: flex;
      gap: 1vw;
      align-items: center;
      margin-bottom: 23px;

      img {
        border-radius: 50%;
        flex: none;
        height: 63px;
        margin-right: 12px;
        object-fit: cover;
        width: 63px;
      }
    }

    .cs_hero_shedule_list {
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        align-items: center;
        display: flex;
        gap: 0.5vw;

        &:not(:last-child) {
          margin-bottom: 3px;
        }
      }
    }
  }
}

.cs_style_2 {
  &.cs_style-14 {
    position: relative;
    border: 1px solid transparent !important;
    border-radius: 20px;
    gap: 10px !important;
    flex-direction: column;
    align-items: flex-start !important;
    min-height: 207px !important;

    .cs_style_14-bg{  
      img{
        border-radius: 20px !important;
      }
    }
    h2,
    p {
      color: rgba(#000, 0.7);
    }

    &:hover {
      background: none !important;
      // transform: translateY(-20px);

      .cs_iconbox_title {
        color: rgba(#000, 0.7) !important;
      }

      .cs_style_14-bg,
      .cs_iconbox_icon {
        img {
          filter: none;
        }
      }
    }

    .cs_style_14-bg {
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0), #f8f9fa);
        // backdrop-filter: blur(1.5px);
        box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.3);
      }
    }

    .cs_iconbox_icon,
    .cs_iconbox_title,
    .cs_iconbox_subtitle {
      opacity: 1;
      z-index: 1;
    }
  }
}

.cs_upload_form {
  .cs_form_field {
    padding: 10px 20px;
    color: $Dgray;
    border: 1px solid $white;
    border-radius: 2.4em;
    background: $white;
  }

  [type="file"]::-webkit-file-upload-button {
    background-image: linear-gradient(
      134deg,
      rgb(247, 146, 38) 0%,
      rgb(240, 84, 69) 100%
    );
    border: none;
    border-radius: 24px;
    color: $white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    padding: 12px 24px;
    transition: all 1s ease;
  }

  [type="file"]::-webkit-file-upload-button:hover {
    background-image: linear-gradient(
      270deg,
      rgb(240, 84, 69) 0% rgb(247, 146, 38) 100%
    );
  }
}

.cs_full_contact_form {
  .cs_form_field {
    padding: 10px 20px;
    border-radius: 2.4em;
  }

  [type="file"]::-webkit-file-upload-button {
    background-image: linear-gradient(
      134deg,
      rgb(247, 146, 38) 0%,
      rgb(240, 84, 69) 100%
    );
    border: none;
    border-radius: 24px;
    color: $white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    padding: 12px 24px;
    transition: all 1s ease;
  }

  [type="file"]::-webkit-file-upload-button:hover {
    background-image: linear-gradient(
      270deg,
      rgb(240, 84, 69) 0% rgb(247, 146, 38) 100%
    );
  }
}

.cs_home_info_sec {
  .cs_iconbox {
    &.cs_style_11 {
      padding: 1px;
      border-radius: 20px;

      .cs_iconbox_icon {
        max-width: none;
        

        @media (max-width: 575px) {
          img {
            min-width: 402px;
          }
        }
      }
      @media (min-width: 1200px) {
        .cs_iconbox_title {
          font-size: 18px;
        }
        .cs_iconbox_right {
          font-size: 12px;
        }
      }

      .cs_iconbox_right {
        padding-right: 20px;

        @media (max-width: 575px) {
          padding: 0 15px 15px;
        }
      }

      @media (max-width: 575px) {
        flex-direction: column;
      }
    }
  }

  .cs_banner_subtitle {
    margin-bottom: 20px;
  }
}

.cs_home_info_sec {
  .contact-us-slider {
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            .cs_style_1 {
              min-height: 640px;

              .cs_banner_subtitle {
                margin-bottom: 20px !important;

                &:last-child {
                  margin-bottom: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.cs_img_with_txt_sec {
  position: relative;
  display: inline-block;
  &.show-tint {
    background: -moz-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ); /* FF3.6+ */
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(0, 0, 0, 0.65)),
      color-stop(100%, rgba(0, 0, 0, 0))
    ); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ); /* Opera 11.10+ */
    background: -ms-linear-gradient(
      top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%
    ); /* IE10+ */
    background: linear-gradient(to bottom, #efe9e98a, #86bef6d1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
  }
  border-radius: 20px;
  min-height: 291px;
  img {
    position: relative;
    z-index: -1;
    display: block;
    min-height: 291px;
    border-radius: 20px;
  }

  .cs_img_with_txt_sec_info {
    padding: 20px;
    position: absolute;
    top: 0;
    bottom: auto;
    background: transparent;
    height: 100%;

    h4 {
      font-size: 18px;
      font-weight: 600;
      line-height: 20px;
      min-height: 60px;
      margin-bottom: 15px;
    }

    p {
      display: -webkit-box;
      max-width: none;
      line-clamp: 4;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 16px;
    }

    .cs_img_with_txt_sec_btn {
      transition: all 0.5s ease;

      &:hover {
        transform: scale(1.03);
      }

      a {
        font-weight: 600;
        background-image: linear-gradient(
          to right,
          rgb(64, 191, 187),
          rgb(7, 146, 71)
        );
        padding: 10px 12px;
        border-radius: 20px;
        color: #fff;
        letter-spacing: 0.3px;
        display: inline-block;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .cs_img_with_txt_sec {
    min-height: auto;

    img {
      min-height: auto;
    }

    .cs_img_with_txt_sec_info {
      h4 {
        min-height: auto;
      }

      .cs_img_with_txt_sec_btn {
        position: absolute;
        bottom: 22px;
        left: auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .cs_img_with_txt_sec {
    min-height: auto;

    img {
      min-height: auto;
    }

    .cs_img_with_txt_sec_info {
      h4 {
        min-height: auto;
      }

      .cs_img_with_txt_sec_btn {
        position: absolute;
        bottom: 22px;
        left: auto;
      }
    }
  }
}

@media (max-width: 575px) {
  .cs_img_with_txt_sec {
    min-height: auto;

    img {
      min-height: auto;
    }

    .cs_img_with_txt_sec_info {
      h4 {
        min-height: auto;
      }

      .cs_img_with_txt_sec_btn {
        position: absolute;
        bottom: 22px;
        left: auto;
      }
    }
  }
}

@media only screen and (max-width: 320px) {
  .cs_hero.cs_style_2.cs_home_page_banner {
    .cs_hero_text {
      .cs_hero_title {
        color: #ffffff;
        width: 100%;
        font-size: 48px;
        line-height: 58px;
      }
    }
  }
}
@media only screen and (max-width: 425px) {
  .cs_hero.cs_style_2.cs_home_page_banner {
    .cs_hero_text {
      .cs_hero_title {
        color: #ffffff;
        width: 100%;
        font-size: 48px;
        line-height: 58px;
      }
    }
  }
}

@media (max-width: 425px) {
  .cs_hero {
    &.cs_style_2 {
      &.cs_home_page_banner {
        .cs_hero_bottom {
          .cs_hero_bottom_right {
            .cs_hero_funfact {
              padding: 10px 0;

              .cs_hero_funfact_col {
                display: flex;
                flex-direction: column;
                align-items: center;

                h3,
                .cs_nav_icon {
                  width: 60%;
                  text-align: center;
                  margin-bottom: 10px;
                  font-size: 22px;
                }

                .cs_nav_icon {
                  display: flex;
                  justify-content: center;
                  padding: 0;
                  width: 50%;
                  img {
                    width: 60%;
                    height: auto;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cs_iconbox_icon {
  img {
    &.info-graphics {
      max-height: 380px;
      object-fit: cover;
    }
  }
}

@media screen and (min-width: 576px) {
  .cs_iconbox_icon {
    img {
      &.info-graphics {
        max-height: 170px;
        min-width: 170px;
      }
    }
  }
}
