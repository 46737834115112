.cs_blog_details {
  .cs_blog_ord_list {
    ol {
      li {
        margin-bottom: 50px;

        ul {
          margin: 20px 0 20px 30px;

          li {
            padding-left: 28px;
            margin-bottom: 20px;
            position: relative;

            &::before {
              background-color: rgb(0, 0, 0, 0.5);
              border-radius: 50%;
              content: "";
              height: 10px;
              left: 0;
              position: absolute;
              top: 8px;
              width: 10px;
            }
          }
        }
      }
    }

    &.cs_blog_ord_list_only {
      ol {
        margin: 0 0 0 30px;
        li {
          margin-bottom: 30px;
        }
      }
    }
  }

  .cs_blog_un_ord_list {
    ul {
      margin: 20px 0 20px 30px;

      li {
        padding-left: 28px;
        margin-bottom: 20px;
        position: relative;

        &::before {
          background-color: rgb(0, 0, 0, 0.5);
          border-radius: 50%;
          content: "";
          height: 10px;
          left: 0;
          position: absolute;
          top: 8px;
          width: 10px;
        }
      }
    }
  }
}

.cs_kidney_education_blogs {
  .cs_post {
    &.cs_style_3 {
      .cs_post_info {
        .cs_post_category {
          border: 1px solid $kidneyEducation;

          &:hover,
          &:focus,
          &:active {
            background-color: $kidneyEducation;
          }
        }

        .cs_posted_by {
          svg {
            path {
              fill: $kidneyEducation;
            }
          }
        }
      }
    }
  }
}

.cs_our_service_blogs {
  .cs_post {
    &.cs_style_3 {
      .cs_post_info {
        .cs_post_category {
          border: 1px solid $ourServices;

          &:hover,
          &:focus,
          &:active {
            background-color: $ourServices;
          }
        }

        .cs_posted_by {
          svg {
            path {
              fill: $ourServices;
            }
          }
        }
      }
    }
  }
}
