.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background-color: white;
    padding: 20px;
    max-width: 100%;
    max-height: 90%;
    overflow-y: auto;
    text-align: center;
    border-radius: 10px;
    position: relative;  // Position relative to hold the close button
  }
  
  .popup-content img {
    width: 100%;
    height: 480px;
    margin-bottom: 10px;
    padding: 5px;
    object-fit: cover;  
    // border: 1px solid black;
    border-radius: 10px;
   
  }
  
  .popup-content p {
    font-size: 18px;
    color: #333;
    margin-top: 10px;
  }
  
  // Close button in the top right
  .close-button {
    position: fixed;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 50px;
    cursor: pointer;
    color: #11911c;
  }
  
  .close-button:hover {
    color: #ff4c4c;
  }
  