.cs_custom_footer {
  position: relative;
  // margin-top: 50px;
  height: 100%;
  background-image: linear-gradient(rgb(64, 191, 187), rgb(7, 146, 71));

  .cs_footer_top_line {
    height: 6px;
    width: 100%;
    // background-image: linear-gradient(rgb(247, 146, 38), rgb(240, 84, 69));
    border-radius: 0;
  }

  .cs_contact_widget {
    margin: 30px 0;
  }

  .cs_footer_item {
    h5 {
      margin: 0;
      padding: 0;
      font-size: 22px;
      font-weight: 500;
      line-height: 28px;
      color: #ffffff;
    }

    .cs_menu_widget {
      margin: 20px 0 0;
      li {
        margin: 0;
        padding: 0 0 10px;

        a {
          font-size: 16px;
          font-weight: 400;

          &:hover,
          &:focus,
          &:active {
            color: rgb(7, 146, 71);
          }
        }
      }
    }

    &.cs_brand_follow_us_section {
      position: relative;
      display: block;
      height: 100%;
      padding: 0;
    }

    .cs_follow_us_section {
      position: absolute;
      bottom: 0;
      left: 0;

      &::after {
        content: "";
        clear: both;
        display: block;
      }

      .cs_social_links {
        padding: 15px 0 0;

        a {
          height: 35px;
          width: 35px;
          background-color: #ffffff;
          color: rgb(64, 191, 187);

          svg {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .cs_newsletter {
      margin: 20px 0 0;
    }
  }

  .cs_footer_bottom {
    .cs_terms_info {
      .cs_menu_widget {
        margin: 0;
        display: flex;

        flex-direction: row;
        li {
          margin: 0;
          padding: 0 12px;
          position: relative;

          &:first-child {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;

            &::after {
              display: none;
            }
          }

          a {
            color: rgba(255, 255, 255, 0.5);

            &:hover,
            &:focus,
            &:active {
              color: rgb(64, 191, 187);
            }
          }

          &::after {
            content: "";
            position: absolute;
            right: 0;
            top: 3px;
            height: 80%;
            width: 0;
            border-left: 3px solid rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .cs_custom_footer {
    .cs_footer_item {
      .cs_follow_us_section {
        position: relative;
        padding: 0 0 30px;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .cs_custom_footer {
    .cs_footer_item {
      .cs_follow_us_section {
        position: relative;
        padding: 0 0 30px;
      }
    }
  }
}
