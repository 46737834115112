.search-section {
    width: 100%;
    height: 500px;
    margin-top: 50px;
    // background-image: url('path_to_your_background_image.png'); /* Your background image URL */
    border: 1px solid black;
    background-color: aliceblue;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .search-container{
    width: 70%;
    // height: 70px;
    // border: 1px solid black;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  /* Custom SCSS */
.search-container .row.no-gutters {
    margin-left: 0;
    margin-right: 0;
  }
  
  .search-container .col-md-4 {
    padding-left: 0;
    padding-right: 0;
  }
  
  .search-container .form-control {
    border-radius: 0; /* Optional: Removes border radius for a more square look */
  }
  
  .search-container .input-group-text {
    border-radius: 0; /* Optional: Matches the input field border */
  }
  .search-container .form-control:focus {
    outline: none; /* Remove the blue outline */
    box-shadow: none; /* Remove the box shadow */
  }
  .search-container .input-group .input-group-text {
    border-radius: 13px 0 0 13px; /* Rounded left side for the search input */
  }
  
  /* For Postal Code Input (Right border-radius) */
  .search-container .col-md-4:last-child .form-control {
    border-radius: 0 13px 13px 0; /* Rounded right side for the postal code input */
  }
  .search-container .form-control {
    padding: 6px; /* Explicit padding of 4px on all sides */
  }
  .rd{
    border-radius: 30px;
  }
  .clickable {
    cursor: pointer;
  }
  .mr{
    margin-top: 700pc;
  }
  .non{
    display: none;
  }