/*--------------------------------------------------------------
9. Sidebar
----------------------------------------------------------------*/
.cs_sidebar {
  padding-left: 100px;
  @media (max-width: 1700px) {
    padding-left: 40px;
  }
  @media (max-width: 1400px) {
    padding-left: 0px;
  }
  @media (max-width: 1199px) {
    .cs_btn.cs_style_1 {
      padding: 12px 30px;
    }
  }
  @media (max-width: 991px) {
    padding-top: 80px;
  }
}
.cs_sidebar_item {
  position: relative;
  border-radius: 6px;
  // background-color: #15860b;
  // background: linear-gradient(180deg, #75c46e, #25c717, #11580e);
  background: linear-gradient(45deg, #760892, #8f0594,#6a11cb);

  border-radius: 8px;
  -webkit-box-shadow:0px 0px 16px 0px rgba(184,178,178,0.93);
  -moz-box-shadow: 0px 0px 16px 0px rgba(184,178,178,0.93);
  box-shadow: 0px 0px 16px 0px rgba(184,178,178,0.93);
  padding: 20px;
  @media (max-width: 1700px) {
    padding: 40px 35px;
  }
  @media (max-width: 1400px) {
    padding: 40px 25px;
  }
  @media (max-width: 700px) {
    // background: linear-gradient(180deg, rgba(117, 196, 110, 0.8), rgba(37, 199, 23, 0.8), rgba(17, 88, 14, 0.8));
    background-size: cover;
    background: linear-gradient(45deg, rgba(118, 8, 146, 0.8), rgba(143, 5, 148, 0.8), rgba(106, 17, 203, 0.8));
    padding: 8px;
    float: right;
    // margin-top: 400px;
    // margin-left: 100px;
  }
  &:not(:last-child) {
    margin-bottom: 70px;
    @media (max-width: 1700px) {
      margin-bottom: 40px;
    }
  }
}
.widget_categories {
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 20px;
    line-height: 1.5em;
    color: $accent;
    font-weight: 500;
    @media (max-width: 1700px) {
      font-size: 18px;
    }
    li {
      position: relative;
      padding-left: 45px;
      &:not(:last-child) {
        margin-bottom: 32px;
        @media (max-width: 1700px) {
          margin-bottom: 22px;
        }
      }
      &::before {
        content: '';
        height: 27px;
        width: 26px;
        display: inline-block;
        background-image: url(../../../public/images/icons/category.svg);
        background-size: contain;
        background-position: center;
        position: absolute;
        left: 0;
        top: 2px;
      }
    }
  }
}
.cs_sidebar_widget_title {
  font-size: 28px;
  font-weight: 600;
  line-height: 1.5em;
  margin-bottom: 30px;
  @media (max-width: 1700px) {
    font-size: 24px;
    margin-bottom: 20px;
  }
}
.cs_popular_posts {
  list-style: none;
  padding: 0;
  margin: 0;
  > li {
    &:not(:last-child) {
      margin-bottom: 40px;
      @media (max-width: 1700px) {
        margin-bottom: 30px;
      }
    }
  }
}

.cs_popular_post_title {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.5em;
  color: $accent;
  margin-bottom: 15px;
  @media (max-width: 1700px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
  a {
    &:hover {
      text-decoration: underline;
    }
  }
}

.cs_popular_post_meta {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 5px 15px;
  justify-content: space-between;
  li {
    display: flex;
    gap: 10px;
    align-items: center;
    &.cs_posted_author {
      a {
        color: $primary;
        &:hover {
          color: $accent;
        }
      }
    }
  }
}
