/*--------------------------------------------------------------
## Color Variable
----------------------------------------------------------------*/
$white: #ffffff;
$black: #000;
$primary: #274760;
$secondary: rgba($primary, 0.52);
$ternary: #b7b7b7;
$border: #eaeaea;
$gray: #fafafa;
$accent: #307bc4;
$Dgray: rgba($black, 0.9);
$kidneyEducation: rgb(247, 146, 38);
// $ourServices: rgb(57, 87, 166);
$ourServices: rgb(7, 146, 71);
$bannerSubTitle: #333333;
