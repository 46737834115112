.cs_counselling {
  .cs_banner {
    &.cs_style_3 {
      &.cs_kidney_education_banner {
        min-height: 510px;
        padding: 0;

        .cs_banner_img {
          align-items: center;
          justify-content: flex-end;
          left: auto;
          padding: 0;
          right: 0;
          width: 45%;
        }

        .cs_banner_text {
          margin-left: 0;
          margin-right: auto;
          padding-left: 0;
          padding-top: 50px;
          padding-bottom: 50px;
          // width: 55%;
          .cs_banner_title,
          .cs_hero_title,
          .cs_banner_subtitle {
            color: $white;
          }

          .cs_banner_subtitle {
            max-width: none;
          }
        }
      }
    }
  }

  &.cs_counselling_iconbox_style1 {
    .cs_section_heading {
      p {
        font-size: 28px;
        font-style: italic;
        color: #333333;
      }
    }

    .cs_awar_with_accordion {
      .cs_iconbox_text {
        padding: 0;
        min-height: 55px;
      }

      .cs_accordians {
        .cs_accordian {
          .cs_accordian_head {
            padding: 15px 45px 15px 15px;
            font-size: 20px;
            line-height: 1.4em;
            min-height: 86px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .cs_accordian_arrow {
              right: 12px;
              top: 30px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &.cs_counselling_iconbox_style1 {
      .cs_awar_with_accordion {
        .cs_iconbox_text {
          min-height: auto;
        }

        .cs_accordians {
          .cs_accordian {
            .cs_accordian_head {
              min-height: auto;

              .cs_accordian_arrow {
                top: 16px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    &.cs_counselling_iconbox_style1 {
      .cs_awar_with_accordion {
        .cs_iconbox_text {
          min-height: 52px;
        }

        .cs_accordians {
          .cs_accordian {
            .cs_accordian_head {
              min-height: 86px;

              .cs_accordian_arrow {
                top: 30px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    &.cs_counselling_iconbox_style1 {
      .cs_awar_with_accordion {
        .cs_iconbox_text {
          min-height: 52px;
        }

        .cs_accordians {
          .cs_accordian {
            .cs_accordian_head {
              min-height: auto;

              .cs_accordian_arrow {
                top: 16px;
              }
            }
          }
        }
      }
    }
  }

  .cs_doctor_details {
    .cs_doctor_title,
    .cs_doctor_description {
      color: $white;
    }
  }

  .cs_iconbox_carousel_1 {
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            .cs_iconbox {
              &.cs_style_10 {
                .cs_iconbox_icon {
                  padding: 0;
                  border: none;
                  background: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .cs_shape_wrap {
    .cs_shape_1 {
      display: none;
    }

    .cs_random_features {
      .cs_iconbox {
        &.cs_style_13 {
          .cs_iconbox_icon {
            padding: 0;
            .cs_iconbox_icon_in {
              background: none;
              padding: 0;
            }
          }
        }
      }
    }
  }

  .cs_iconbox {
    &.cs_style_1 {
      .cs_iconbox_top {
        .cs_iconbox_icon {
          padding: 0;
          background: none;
        }
      }
    }
  }

  .cs_gray_bg_1 {
    &.cs_half_bg {
      background: none;
    }
  }

  .cs_about {
    &.cs_style_3 {
      background: linear-gradient(
        119deg,
        rgb(64, 191, 187) 17.16%,
        rgb(7, 146, 71) 89.78%
      );

      .cs_section_heading {
        &.cs_style_1 {
          .cs_section_title {
            margin-bottom: 20px !important;
          }

          .cs_section_title,
          .cs_section_minititle {
            color: $white;
          }
        }
      }
    }
  }

  .cs_iconbox_8_wrap {
    background: none;

    .cs_iconbox {
      &.cs_style_8 {
        .cs_iconbox_icon {
          border: none;
        }

        .cs_iconbox_title,
        .cs_iconbox_subtitle {
          color: #000;
        }
      }
    }
  }

  .cs_departments {
    &.cs_style_1 {
      .cs_section_heading {
        .cs_section_title,
        p {
          color: #fff;
        }
      }

      .cs_department_list {
        .cs_banner {
          &.cs_style_9 {
            margin: 0;
            padding: 50px 10px;

            .cs_accordian_body {
              ul {
                li {
                  &:before {
                    background-color: $ourServices;
                  }

                  .cs_heading_color {
                    text-decoration: underline;
                    text-decoration-color: $ourServices;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.cs_lab_test {
  .cs_departments {
    &.cs_style_1 {
      .cs_section_heading {
        &.cs_style_1 {
          .cs_section_title,
          p {
            color: $white;
          }

          p {
            font-size: 32px;
            font-weight: 500;
            line-height: 30px;
          }
        }
      }

      .cs_department_list {
        .cs_banner {
          &.cs_style_9 {
            margin: 0;
            padding: 50px 10px;
          }
        }
      }
    }
  }
}

.cs_service-catheters {
  .cs_banner {
    &.cs_style_1 {
      .cs_banner_img {
        bottom: 0;
        right: 0;
      }

      .cs_banner_title,
      .cs_banner_subtitle {
        color: $white;
      }

      .cs_banner_subtitle {
        max-width: 650px;
      }
    }
  }

  .cs_shape_wrap {
    .cs_shape_1 {
      &.cs_position_2 {
        top: 0;
        display: none;
      }
    }

    .cs_iconbox_3_wrap {
      .cs_iconbox {
        &.cs_style_3 {
          &::before {
            background-color: $ourServices;
          }

          .cs_iconbox_left {
            .cs_iconbox_icon {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .cs_departments {
    &.cs_style_1 {
      .cs_section_heading {
        .cs_section_title {
          color: $ourServices;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .cs_service-catheters {
    .cs_banner {
      &.cs_style_1 {
        padding: 100px 30px;
        .cs_banner_img {
          right: -30px;
        }

        .cs_banner_subtitle {
          max-width: 650px;
        }
      }
    }
  }
}

.cs_dialysis_accessories {
  .cs_iconbox_12_wrap {
    .cs_iconbox {
      &.cs_style_12 {
        .cs_iconbox_info {
          .cs_iconbox_circle {
            background-color: $ourServices;
          }
        }

        .cs_iconbox_icon,
        a {
          padding: 0;
          cursor: default;

          &:hover {
            color: inherit;
          }

          img {
            filter: none;
          }
        }
      }
    }
  }

  .cs_iconbox {
    &.cs_style_6 {
      .cs_iconbox_icon {
        background: none;
        padding: 0;
      }

      .cs_iconbox_title {
        text-decoration: underline;
        text-decoration-color: $ourServices;
      }
    }
  }
}

.cs_healthy_records {
  &.cs_hero {
    &.cs_style_1 {
      padding-bottom: 0;
      height: auto;

      .cs_hero_img {
        bottom: 0;
      }

      .container {
        .cs_hero_text {
          padding-bottom: 20px;
          .cs_hero_subtitle {
            margin-bottom: 20px;
          }
        }
      }
    }
  }

  .cs_shape_wrap {
    .cs_shape_1 {
      display: none;
    }
  }

  .cs_banner {
    &.cs_style_6 {
      background: linear-gradient(
        154deg,
        rgb(64, 191, 187) 0%,
        rgb(7, 146, 71) 100%
      );

      .cs_banner_title,
      .cs_banner_subtitle {
        color: $white;
      }
    }
  }
}
.cs_why_chooseM {
  margin-left: 115px;
  margin-top: 30px;
}
.cs_beyond {
  h2 {
    font-size: 20px;
    font-weight: 100;
  }
}
@media (max-width: 1700px) {
  .cs_healthy_records {
    .cs_banner {
      &.cs_style_6 {
        padding: 86px 70px 240px 50.4%;

        .cs_banner_img {
          bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .cs_healthy_records {
    .cs_banner {
      &.cs_style_6 {
        padding: 80px 30px 80px 55%;

        .cs_banner_img {
          left: -30px;
          bottom: 5%;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .cs_healthy_records {
    &.cs_hero {
      &.cs_style_1 {
        .cs_hero_img {
          margin: 0;
        }
      }
    }

    .cs_banner {
      &.cs_style_6 {
        padding: 70px 30px;

        .cs_banner_img {
          bottom: 0;
        }
      }
    }
  }
}

.cs_kidney_biopsy_gun {
  .cs_banner {
    &.cs_style_4 {
      padding: 140px 36% 340px 70px;
    }
  }
}

@media (max-width: 1199px) {
  .cs_kidney_biopsy_gun {
    .cs_banner {
      &.cs_style_4 {
        padding: 40px 36% 40px 70px;
      }
    }
  }
}

@media (max-width: 768px) {
  .cs_kidney_biopsy_gun {
    .cs_banner {
      &.cs_style_4 {
        padding: 40px 31% 40px 30px;
      }
    }
  }
}

@media (max-width: 575px) {
  .cs_kidney_biopsy_gun {
    .cs_banner {
      &.cs_style_4 {
        padding: 40px 30px;
      }
    }
  }
}

.cs_health_records {
  .cs_banner {
    &.cs_style_3 {
      &.cs_kidney_education_banner {
        min-height: 480px;
        padding: 0;

        .cs_banner_img {
          padding: 0;
          right: 0;
          left: auto;
          top: auto;
          bottom: 0;
          align-items: center;
          justify-content: flex-end;
        }

        .cs_banner_subtitle {
          max-width: none;
        }

        .cs_banner_text {
          padding-top: 50px;
          padding-bottom: 50px;
          padding-left: 0;
          margin-left: 0;
          margin-right: auto;
          width: 60%;

          .cs_banner_title,
          .cs_hero_title,
          .cs_banner_subtitle {
            color: #fff;
          }
        }
      }
    }
  }
}

.cs_kidney_diet {
  .cs_banner {
    &.cs_style_3 {
      &.cs_kidney_education_banner {
        min-height: 580px;
        padding: 0;

        .cs_banner_img {
          padding: 0;
          right: 0;
          left: auto;
          top: auto;
          bottom: 0;
          align-items: center;
          justify-content: flex-end;
        }

        .cs_banner_subtitle {
          max-width: none;
        }

        .cs_banner_text {
          padding-top: 50px;
          padding-bottom: 50px;
          padding-left: 0;
          margin-left: 0;
          margin-right: auto;
          width: 60%;

          .cs_banner_title,
          .cs_hero_title,
          .cs_banner_subtitle {
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .cs_counselling_img_txt_sec {
    .cs_img_with_txt_sec {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 768px) {
  .cs_counselling_img_txt_sec {
    .cs_img_with_txt_sec {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
}

@media (max-width: 575px) {
  .cs_counselling_img_txt_sec {
    .cs_img_with_txt_sec {
      width: 100%;

      img {
        width: 100%;
      }
    }
  }
}
