.cs_banner_styles {
  .cs_banner {
    &.cs_style_3 {
      &.cs_kidney_needs_banner {
        min-height: 490px;
        padding: 0;

        .cs_banner_img {
          align-items: center;
          justify-content: flex-end;
          left: auto;
          padding: 0;
          right: 0;
          width: 35%;
        }

        .cs_banner_text {
          margin-left: 0;
          margin-right: auto;
          padding-top: 50px;
          padding-bottom: 50px;
          padding-left: 0;
          width: 70%;

          .cs_banner_title,
          .cs_banner_subtitle,
          .cs_hero_subtitle {
            color: $white;
          }

          .cs_banner_subtitle {
            max-width: none;
            margin-bottom: 15px !important;
          }

          .cs_hero_title {
            // color: #333333;
            font-style: italic;
            opacity: 0.7;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .cs_banner_styles {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_needs_banner {
          .cs_banner_img {
            width: 25%;
          }

          .cs_banner_text {
            width: 75%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .cs_banner_styles {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_needs_banner {
          .cs_banner_img {
            width: auto;
          }

          .cs_banner_text {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .cs_banner_styles {
    .cs_banner {
      &.cs_style_3 {
        &.cs_kidney_needs_banner {
          .cs_banner_text {
            width: 100%;

            .cs_hero_title {
              line-height: 34px;
            }
          }
        }
      }
    }
  }
}
.center{
  text-align: center;
 margin-top: 13px;
}
